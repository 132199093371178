#app {
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
/* *::-webkit-scrollbar {
  width: 0.35em;
  height: 0.35em;
} */

*::-webkit-scrollbar-thumb {
  background-color: rgba(205, 27, 84, 0.2);
  outline: 1px solid slategrey;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
